import React from 'react';
import { Spin, Row, Col } from 'antd';

class Loading extends React.Component {

    render() {
        return (
            <Row justify="center" align="middle" style={{ height: '100vh' }}>
                <Col>
                    <Spin tip="Cargando..."></Spin>
                </Col>
            </Row>
        );
    }
}

export default Loading;